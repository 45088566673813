import './TableRowGroup.scss';

import cx from 'classnames';
import React from 'react';

const TableRowGroup = ({ children, logo }) => {
  const rootCx = cx('table-row-group', {
    'table-row-group--logo': logo,
  });

  return <div className={rootCx}>{children}</div>;
};

export const TableLogoRowGroup = (props) => <TableRowGroup logo {...props} />;

export default TableRowGroup;
