import './TableRow.scss';

import cx from 'classnames';
import React from 'react';

const TableRow = ({ children, header, logo }) => {
  const rootCx = cx('table-row', {
    'table-row--header': header,
    'table-row--logo': logo,
  });

  return <div className={rootCx}>{children}</div>;
};

export default TableRow;
