import './ProcessS2.scss';

import { RichText } from 'prismic-reactjs';
import React from 'react';

import Col from '../../../components/Col/Col';
import Heading from '../../../components/Heading/Heading';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';
import Table from '../../../components/Table/Table';
import TableRow from '../../../components/Table/TableRow';
import Text from '../../../components/Text/Text';

const ProcessS2 = ({ descRT = {}, headingRT = {}, tableRows = [], tableHeaderRT = {} }) => {
  if (!headingRT.text && !tableRows.length) {
    return null;
  }

  return (
    <Section className="process-s2">
      <Row>
        {/* left */}
        <Col className="process-s2__left" xx={12} md={4} mdOffset={1}>
          <ScrollTrigger>
            {headingRT.text && (
              <Heading>
                <RichText render={headingRT.raw} />
              </Heading>
            )}
            {descRT.text && (
              <Text>
                <RichText render={descRT.raw} />
              </Text>
            )}
          </ScrollTrigger>
        </Col>
        {/* right */}
        <Col xx={12} md={5} mdOffset={1}>
          <ScrollTrigger desktopSet={{ to: { delay: 0.4 } }}>
            {tableRows.length > 0 && (
              <Table>
                {tableHeaderRT.text && (
                  <TableRow header>
                    <Text>
                      <RichText render={tableHeaderRT.raw} />
                    </Text>
                  </TableRow>
                )}
                {tableRows.map((row) => (
                  <TableRow key={row.text.text}>
                    <Text>
                      <RichText render={row.text.raw} />
                    </Text>
                  </TableRow>
                ))}
              </Table>
            )}
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default ProcessS2;
