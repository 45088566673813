import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import React, { useRef } from 'react';

import ProcessHero from './ProcessHero/ProcessHero';
import ProcessS1 from './ProcessS1/ProcessS1';
import ProcessS2 from './ProcessS2/ProcessS2';
import ProcessS3 from './ProcessS3/ProcessS3';
import ProcessS4 from './ProcessS4/ProcessS4';

const OurProcessPage = ({
  data: {
    page: {
      data: {
        hero_opening_text,
        section_1_image,
        section_2_desc,
        section_2_table_header_text,
        section_2_table_rows,
        section_2_title,
        section_3_desc,
        section_3_table_header_text,
        section_3_table_image,
        section_3_table_rows,
        section_3_title,
        section_4_image,
        section_4_table_rows,
        section_4_title,
      } = {},
    } = {},
  } = {},
}) => {
  const heroRef = useRef(null);

  return (
    <React.Fragment>
      {/* Hero */}
      <ProcessHero openingRT={hero_opening_text} ref={heroRef} />

      {/* Section 1 */}
      <ProcessS1 openingIMG={section_1_image} animationTrigger={heroRef.current} />

      {/* Section 2 */}
      <ProcessS2
        descRT={section_2_desc}
        headingRT={section_2_title}
        tableHeaderRT={section_2_table_header_text}
        tableRows={section_2_table_rows}
      />

      {/* Section 3 */}
      <ProcessS3
        descRT={section_3_desc}
        headingRT={section_3_title}
        tableHeaderRT={section_3_table_header_text}
        tableIMG={section_3_table_image}
        tableRows={section_3_table_rows}
      />

      {/* Section 4 */}
      <ProcessS4 headIMG={section_4_image} headingRT={section_4_title} tableRows={section_4_table_rows} />
    </React.Fragment>
  );
};

export default withPreview(OurProcessPage);

export const query = graphql`
  query($lang: String) {
    page: prismicOurProcessPage(lang: { eq: $lang }) {
      data {
        hero_opening_text {
          raw
          text
        }

        section_1_image {
          alt
          fluid(maxWidth: 880) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }

        section_2_desc {
          raw
          text
        }
        section_2_table_header_text {
          raw
          text
        }
        section_2_table_rows {
          text {
            raw
            text
          }
        }
        section_2_title {
          raw
          text
        }

        section_3_desc {
          raw
          text
        }
        section_3_table_header_text {
          raw
          text
        }
        section_3_table_image {
          alt
          url
        }
        section_3_table_rows {
          text {
            raw
            text
          }
          expanding_text {
            raw
            text
          }
        }
        section_3_title {
          raw
          text
        }

        section_4_image {
          alt
          fluid(maxWidth: 410) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }
        section_4_table_rows {
          text {
            raw
            text
          }
          title {
            raw
            text
          }
        }
        section_4_title {
          raw
          text
        }
      }
    }
  }
`;
