import './ProcessS3.scss';

import { RichText } from 'prismic-reactjs';
import React from 'react';

import Col from '../../../components/Col/Col';
import ExpandablePanel from '../../../components/ExpandablePanel/ExpandablePanel';
import Heading from '../../../components/Heading/Heading';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';
import Table from '../../../components/Table/Table';
import TableRow from '../../../components/Table/TableRow';
import { TableLogoRowGroup } from '../../../components/Table/TableRowGroup';
import Text from '../../../components/Text/Text';

const ProcessS3 = ({ descRT = {}, headingRT = {}, tableIMG, tableRows = [], tableHeaderRT = {} }) => {
  if (!headingRT.text && !tableRows.length) {
    return null;
  }

  return (
    <Section className="process-s3">
      <Row mdJustify="center">
        {/* top */}
        <Col xx={12} md={8} className="process-s3__top">
          <ScrollTrigger>
            {headingRT.text && (
              <Heading>
                <RichText render={headingRT.raw} />
              </Heading>
            )}
            {descRT.text && (
              <Text>
                <RichText render={descRT.raw} />
              </Text>
            )}
          </ScrollTrigger>
        </Col>
        {/* bottom */}
        <Col xx={12} md={8} className="process-s3__bottom">
          <ScrollTrigger>
            {tableRows.length > 0 && (
              <Table>
                {tableHeaderRT.text && (
                  <TableRow header>
                    <Text>
                      <RichText render={tableHeaderRT.raw} />
                    </Text>
                  </TableRow>
                )}
                <TableLogoRowGroup>
                  <TableRow logo>
                    <img src={tableIMG.url} alt={tableIMG.alt} />
                  </TableRow>
                  {tableRows.map((row) => (
                    <TableRow key={row.text.text}>
                      <ExpandablePanel descRT={row.expanding_text} titleRT={row.text} />
                    </TableRow>
                  ))}
                </TableLogoRowGroup>
              </Table>
            )}
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default ProcessS3;
