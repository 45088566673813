import './ProcessS1.scss';

import React from 'react';

import Col from '../../../components/Col/Col';
import Image from '../../../components/Image/Image';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';

const ProcessS1 = ({ animationTrigger, openingIMG = {} }) => {
  if (!openingIMG.fluid) {
    return null;
  }

  return (
    <Section className="process-s1">
      <Row mdJustify="center">
        <Col md={8}>
          <ScrollTrigger allSet={{ to: { delay: 0.4, scrollTrigger: { trigger: animationTrigger } } }}>
            <Image {...openingIMG} />
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default ProcessS1;
