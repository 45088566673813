import './ExpandablePanel.scss';

import cx from 'classnames';
import { RichText } from 'prismic-reactjs';
import React, { useState } from 'react';

import minus from '../../images/minus.svg';
import plus from '../../images/plus.svg';
import Text from '../Text/Text';

const ExpandablePanel = ({ descRT = {}, titleRT = {} }) => {
  const [isOpen, setIsOpen] = useState(false);

  const rootCx = cx('expandable-panel', {
    'expandable-panel--open': isOpen,
  });

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={rootCx}>
      <div className="expandable-panel__top">
        {titleRT.text && (
          <Text>
            <RichText render={titleRT.raw} />
          </Text>
        )}
        {descRT.text && (
          <button className="expandable-panel__button" onClick={handleButtonClick} type="button">
            <img src={isOpen ? minus : plus} alt="" />
          </button>
        )}
      </div>
      {descRT.text && (
        <div className="expandable-panel__bottom">
          <Text>
            <RichText render={descRT.raw} />
          </Text>
        </div>
      )}
    </div>
  );
};

export default ExpandablePanel;
